import React from "react";
import PortableText from "./portableText";
import { MaxWidth, PageTitle, PageDivider } from "./ui-components";
import styled from "styled-components";
import FeaturedPostsGrid from "./FeaturedPostsGrid";

function Page(props) {
  const { _rawBody, title, featuredPostsLayout, posts } = props;
  const { firstRow, secondRow, remainingRows } = featuredPostsLayout;

  return (
    <>
      <div>
        <MaxWidth>
          <PageTitle>{title}</PageTitle>
        </MaxWidth>
        <PageDivider />

        <MaxWidth>{_rawBody && <PortableText blocks={_rawBody} />}</MaxWidth>

        <MaxWidth>
          <FeaturedPostsGrid
            firstRow={firstRow}
            secondRow={secondRow}
            remainingRows={remainingRows}
            posts={posts}
          />
        </MaxWidth>
      </div>
    </>
  );
}

export default Page;
