import React from "react";
import { graphql } from "gatsby";
import Page from "../components/page";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { toPlainText } from "../lib/helpers";

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      featuredPostsLayout {
        firstRow
        secondRow
        remainingRows
      }
      posts {
        _id
        title
        publishedAt(formatString: "MMM DD, YYYY")
        slug {
          current
        }
        mainImage {
          asset {
            fluid(maxWidth: 768) {
              ...GatsbySanityImageFluid
            }
          }
        }
        authors {
          author {
            id
            name
            image {
              asset {
                fixed(width: 36) {
                  ...GatsbySanityImageFixed_noBase64
                }
              }
            }
            slug {
              current
            }
          }
        }
        _rawExcerpt(resolveReferences: { maxDepth: 5 })
        _rawBody(resolveReferences: { maxDepth: 5 })
      }
    }
  }
`;

const PageTemplate = (props) => {
  const { data, location } = props;
  const page = data && data.page;
  return (
    <Layout location={location.pathname}>
      {page && <SEO title={page.title || "Untitled"} description={toPlainText(page._rawExcerpt)} />}
      {page && <Page {...page} />}
    </Layout>
  );
};

export default PageTemplate;
